<template>
  <v-container fluid>
    <v-row>
      <v-col lg="3" md="6" cols="12">
        <riegenkarten :id="id" :e="e" :r="r" :df="df" />
      </v-col>
      <v-col lg="3" md="6" cols="12">
        <mannschaftsuebersicht :id="id" :e="e" :r="r" :df="df" />
      </v-col>
      <v-col lg="3" md="6" cols="12">
        <beteiligte :id="id" :e="e" :df="df" />
      </v-col>
      <v-col lg="3" md="6" cols="12">
        <qrcode :id="id" :e="e" :df="df" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'

import { useCalc } from '@/views/components/stb_m_2021/plugins/calc'
import { usePdf } from '@/views/components/stb_m_2021/plugins/pdf'
import { useAuth } from '@/plugins/auth'

export default {
  name: 'wk2',

  setup (props, context) {
    return {
      ...useAuth(context),
      ...useGraphQL(context),
      ...useCalc(props, context),
      ...usePdf(context)
    }
  },

  components: {
    beteiligte: () => import('../../general/beteiligte'),
    qrcode: () => import('../../general/qrcode'),
    riegenkarten: () => import('../../general/riegenkarten'),
    mannschaftsuebersicht: () => import('../../general/mannschaftsuebersicht')
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
